<template>
  <section class="app-ecommerce-details">
    <!-- Content -->
    <b-overlay :show="showLoading" spinner-variant="primary">
      <b-modal v-if="product != null" centered size="sm" id="modal-prevent-closing" ref="arrival-modal"
        :title="product.Description" hide-footer>
        <b-form-input placeholder="La quantité demandée" ref="qte-input" v-model="requestedQte" type="number"
          @keyup.enter="handleOk"></b-form-input>
        <div class="d-block text-center">
          <b-button variant="relief-success" class="mt-1" @click="handleOk">Valider</b-button>
        </div>
      </b-modal>

      <b-card v-if="product" no-body>
        <b-card-body>
          <b-row class="my-2">

            <!-- Left: Product Image Container -->
            <b-col cols="12" md="5" class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
              :key="productKey">
              <b-overlay :show="showLoading" spinner-variant="primary">
                <div class="d-flex align-items-center justify-content-center">
                  <div v-if="imageLoading" class="loading">
                    <!-- Show loading image or spinner -->
                  </div>
                  <b-img v-else :src="product.image" :alt="`Image of ${product.name}`" class="product-img" fluid />
                </div>
              </b-overlay>
            </b-col>

            <!-- Right: Product Details -->
            <b-col cols="12" md="7">

              <!-- Product Name -->
              <h4>{{ product.Description }}</h4>

              <!-- Product Brand -->
              <b-card-text class="item-company mb-0">
                <span>fourni par</span>
                <b-link class="company-name">
                  {{ product.Nom_Fournisseur }}
                </b-link>
              </b-card-text>

              <!-- Price And Ratings -->
              <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                <h4 class="item-price mr-1">
                  {{ formatPrice(product.Unit_Price_pharmacien_TTC) }} TND
                </h4>
                <ul class="unstyled-list list-inline pl-1 border-left">
                  <li class="ratings-list-item ml-25" v-for="star in 5" :key="star">
                    <feather-icon icon="StarIcon" size="16" :class="[getRatingColor(), getRatingClass(star)]" />
                  </li>
                </ul>
              </div>

              <!-- Avability -->
              <b-card-text>Prix H.T - <span class="text-success">{{ formatPrice(product.Unit_Price_pharmacien) }}
                  TND</span></b-card-text>
              <b-card-text>Prix T.T.C - <span class="text-success">{{ formatPrice(product.Unit_Price_pharmacien_TTC) }}
                  TND</span></b-card-text>
              <b-card-text>Prix Public - <span class="text-success">{{ formatPrice(product.Prix_Public) }}
                  TND</span></b-card-text>

              <!-- Product Description -->
              <!-- <b-card-text>{{ product.Description }}</b-card-text> -->


              <!-- <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <ul class="unstyled-list list-inline ">
                <li class="ratings-list-item mr-25 border-right">
                  <b-card-text>Prix H.T : <span class="text-success">{{ product.Unit_Price_pharmacien }} TND</span></b-card-text>
                </li>
                <li class="ratings-list-item mr-25 border-right">
                  <b-card-text>Prix T.T.C : <span class="text-success">{{ product.Unit_Price_pharmacien_TTC }} TND</span></b-card-text>
                </li>
                <li class="ratings-list-item mr-25 border-right">
                  <b-card-text>Prix Public : <span class="text-success">{{ product.Prix_Public }} TND</span></b-card-text>
                </li>
              </ul>
            </div> -->


              <!-- Product Meta [Free shpping, EMI, etc.] -->
              <ul class="product-features list-unstyled">
                <li>
                  <feather-icon icon="LayersIcon" />
                  <span>Catégorie : <b-badge :variant="getCategoryColor()">
                      {{ product.Family }}
                    </b-badge></span>
                </li>
                <li>
                  <feather-icon icon="FolderIcon" />
                  <span>Famille : {{ product.Subfamily }}</span>
                </li>
              </ul>

              <hr>

              <!-- Colors -->
              <div class="product-color-options">
                <ul class="product-features list-unstyled">
                  <li>
                    <feather-icon icon="ClockIcon" />
                    <span>DLC : {{ product.DLC }}</span>
                  </li>
                  <li>
                    <feather-icon icon="InfoIcon" />
                    <span>DCI : {{ product.DCI }}</span>
                  </li>
                  <li>
                    <feather-icon icon="CodeIcon" />
                    <span>PCT : {{ product.Code_PCT }}</span>
                  </li>
                </ul>
              </div>

              <hr>

              <div class="d-flex flex-column flex-sm-row pt-1">

                <b-button v-if="!isAdministrator" variant="primary" class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="handleCartActionClick(product)">
                  <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                  <span>Ajouter aux Favoris</span>
                </b-button>
                <!-- <b-button v-if="!isAdministrator" variant="outline-secondary"
                  class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0" @click="toggleProductInWishlist(product)">
                  <feather-icon icon="HeartIcon" class="mr-50" :class="{ 'text-danger': product.isInWishlist }" />
                  <span>Wishlist</span>
                </b-button> -->
                <div v-if="isAdministrator" class="mr-5 hover-hand">
                  <b-form-file accept="image/*" browse-text="Modifier l'image" @change="handleFileChange" />
                </div>

                <!-- <b-dropdown variant="outline-secondary" no-caret toggle-class="btn-icon" class="btn-share" right>
                  <template #button-content>
                    <feather-icon icon="Share2Icon" />
                  </template>
                  <b-dropdown-item v-for="icon in ['FacebookIcon', 'TwitterIcon', 'YoutubeIcon', 'InstagramIcon',]"
                    :key="icon">
                    <feather-icon :icon="icon" />
                  </b-dropdown-item>
                </b-dropdown> -->
              </div>
            </b-col>
          </b-row>
        </b-card-body>

        <!-- Static Content -->
        <!-- <div class="item-features">
          <div class="row text-center">
          </div>
        </div> -->

        <!-- Static Content -->
        <!-- Slider: Related Products -->
        <e-commerce-product-details-related-products @goTo="handleEventGoTo" :key="relatedProductsKey" />
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import { useRouter } from '@core/utils/utils'
import { ref, watch } from '@vue/composition-api'
import { formatPrice } from "@/utils/formatting";
import ECommerceProductDetailsRelatedProducts from './ProductDetailsRelatedProducts.vue'
import {
  isAdministrator,
} from "@/auth/utils";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'

export default {
  components: {
    // SFC
    ECommerceProductDetailsRelatedProducts,
  },
  data() {
    return {
      showLoading: false,
      requestedQte: '',
      formatPrice: formatPrice,
      isAdministrator: isAdministrator(),
      relatedProductsKey: 0,
    }
  },
  setup() {
    // UI
    const { route } = useRouter();
    let productSlug = ref(route.value.params.slug);
    const product = ref(null);
    const defaultImage = require('@/assets/images/default.jpg');
    const imageLoading = ref(true);
    const productKey = ref(0);

    const fetchProduct = async () => {
      try {
        const response = await axios.get("item/findById", {
          params: {
            "No": productSlug.value
          }
        });
        product.value = response.data;
        const imageResponse = await axios.get("file/" + productSlug.value);
        if (imageResponse.data.content) {
          product.value["image"] = 'data:image/jpeg;base64,' + imageResponse.data.content;
        } else {
          product.value["image"] = defaultImage;
        }
        imageLoading.value = false;
        productKey.value = Math.random();
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    fetchProduct();

    watch(
      () => route.value.params.slug,
      (newSlug) => {
        // Handle the route parameter changes
        productSlug.value = newSlug;
        fetchProduct();
      }
    )

    return {
      product,
      imageLoading,
      productKey
    };
  },
  methods: {
    handleCartActionClick() {
      this.requestedQte = "";
      this.$refs['arrival-modal'].show();
      setTimeout(() => {
        this.$refs["qte-input"].focus();
      }, 400);
    },
    handleOk() {
      this.$refs['arrival-modal'].hide();
      this.showLoading = true;
      this.$store.dispatch('app-portaldata/GET_INVENTORY', { code: this.product.No, qte: this.requestedQte }).then((response) => {
        this.showLoading = false;
        this.$toast.clear();
        this.$toast({
          component: ToastificationContent,
          props: response,
        });
        this.product["promotion"] = response.promotion;
        this.product["state"] = !["warning", "danger"].includes(response.variant);
        if (this.product.state) {
          this.product["Qte"] = this.requestedQte;
        }
      }).catch((error) => {
        this.showLoading = false;
        console.log(error.response);
      });
    },
    handleFileChange(event) {
      this.showLoading = true;
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = async (readerEvt) => {
          const binaryString = readerEvt.target.result;
          const filestring = btoa(binaryString);
          try {
            await this.sendApiRequest(filestring, file.name);
            this.showLoading = false;
            this.$swal.fire({
              position: "absolute",
              icon: "success",
              title: "Mise à jour effectuée avec succès",
              showConfirmButton: true,
              timer: 5000
            });
          } catch (error) {
            this.showLoading = false;
          }
        };
        reader.readAsBinaryString(file);
      } else {
        // Handle the case when no file is selected
      }
    },
    getFileExtension(filename) {
      const parts = filename.split('.');
      return parts[parts.length - 1];
    },
    sendApiRequest(image, fileName) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("idObject", this.$route.params.slug);
        formData.append("name", fileName);
        formData.append("extension", this.getFileExtension(fileName));
        formData.append("content", image);
        let response = this.$http.post("file", formData).catch((error) => {
          reject(error);
        });
        this.product.image = 'data:image/jpeg;base64,' + response.data.content;
        this.productKey += 1;
        resolve();
      });
    },
    handleEventGoTo(code) {
      this.$router.replace({
        name: "product-details",
        params: {
          slug: code
        },
      });
      this.relatedProductsKey += 1;
    },
    getRatingColor() {
      switch (this.product.Family) {
        case 'MEDICAMENT':
          return 'text-warning';
        case 'PARA':
          return 'text-mueted';
        case 'ACCESSOIRE':
          return 'text-default';
        default:
          return 'text-warning';
      }
    },
    getRatingClass(star) {
      if (this.product.Matrice.startsWith('A'))
        return 'fill-current';
      if (this.product.Matrice.startsWith('B') && star <= 3)
        return 'fill-current';
      if (this.product.Matrice.startsWith('C') && star <= 1)
        return 'fill-current';
    },
    getCategoryColor() {
      switch (this.product.Family) {
        case 'MEDICAMENT':
          return 'light-primary';
        case 'PARA':
          return 'light-success';
        case 'ACCESSOIRE':
          return 'light-warning';
        default:
          return 'light-info';
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";

.hover-hand .file-input {
  cursor: pointer;
}
</style>
