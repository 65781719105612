<template>
  <b-card-body>
    <div class="mt-4 mb-2 text-center">
      <h4>Articles similaires</h4>
      <b-card-text>Les pharmaciens font recherche également à ces articles</b-card-text>
    </div>

    <!-- Swiper -->
    <swiper class="swiper-responsive-breakpoints px-4 py-2" :options="swiperOptions" :key="productKey">
      <swiper-slide v-for="(product, index) in relatedProducts" :key="index" v-show="product.No != productSlug">
        <b-link @click="goTo(product)">
          <div class="item-heading">
            <h5 class="text-truncate mb-0">
              {{ product.Description }}
            </h5>
            <small class="text-body">by {{ product.Nom_Fournisseur }}</small>
          </div>
          <div class="img-container w-50 mx-auto py-75">
            <div v-if="product.imageLoading === true" class="loading">
              <!-- Show loading image or spinner -->
            </div>

            <div v-else>
              <b-img v-if="!product.image" :alt="`${product.Description}-${product.No}`" fluid
                class="card-img-top product-img" :src="defaultImage" />
              <b-img v-else :alt="`${product.Description}-${product.No}`" fluid class="card-img-top product-img"
                :src="'data:image/jpeg;base64,' + product.image" />
            </div>
          </div>
          <div class="item-meta">
            <ul class="unstyled-list list-inline mb-25">
            </ul>
            <p class="card-text text-primary mb-0">
              {{ formatPrice(product.Unit_Price_pharmacien_TTC) }} TND
            </p>
          </div>
        </b-link>
      </swiper-slide>

      <!-- Add Arrows -->
      <div slot="button-next" class="swiper-button-next" />
      <div slot="button-prev" class="swiper-button-prev" />
    </swiper>
  </b-card-body>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import { formatPrice } from "@/utils/formatting";
import axios from '@axios'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    // const productCode = ref(props.productSlug);
    const { route } = useRouter();
    const productSlug = route.value.params.slug;
    const defaultImage = require('@/assets/images/default.jpg');
    const productKey = ref(0);
    const swiperOptions = {
      slidesPerView: 5,
      spaceBetween: 55,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1600: {
          slidesPerView: 4,
          spaceBetween: 55,
        },
        1300: {
          slidesPerView: 3,
          spaceBetween: 55,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 55,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 55,
        },
      },
    }
    const relatedProducts = ref(null);
    const fetchProduct = async () => {
      try {
        const resp = await axios.get("item/findById", {
          params: {
            "No": productSlug
          }
        });
        let response;
        if (resp.data.FamilyNo == "PARA") {
          response = await axios.post("item/findWhere/top/10", [{ "field": "No", "value": productSlug, "operator": "ne" }
            , { "field": "CategoryNo", "value": resp.data.CategoryNo, "operator": "eq" }
            , { "field": "SubfamilyNo", "value": resp.data.SubfamilyNo, "operator": "eq" }]);
        } else {
          response = await axios.post("item/findWhere/top/10", [{ "field": "No", "value": productSlug, "operator": "ne" }
            , { "field": "DCI", "value": resp.data.DCI, "operator": "eq" }]);
        }
        relatedProducts.value = response.data;
        response.data.forEach(element => {
          element.imageLoading = true;
          axios.get("file/" + element.No).then(response => {
            element["image"] = response.data.content;
            element.Creation_Date += 1;
            element.imageLoading = false;
            productKey.value += 1;
          })
        });
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    fetchProduct();

    return {
      swiperOptions,
      relatedProducts,
      defaultImage,
      productSlug,
      productKey,
      formatPrice: formatPrice // Corrected line
    }
  },
  methods: {
    goTo(product) {
      this.$emit('goTo', product.No);
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';
</style>
